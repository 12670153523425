window.addEventListener("DOMContentLoaded", () => {
    console.log(':)');
    try {
        const anchors = document.querySelectorAll(".anchor");
        anchors.forEach((item) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();
                const blockID = item.getAttribute('href').substr(1);
                document.getElementById(blockID).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
                anchors.forEach((i) => {
                    i.classList.remove("active");
                });
                item.classList.add("active");
            })
        });
    }catch(e){
        console.log(e + "якоря");
    }

    try{

        let human = document.querySelector(".human"),
            header = document.querySelector("#header"),
            top = document.querySelector("#top");

        window.addEventListener('scroll', () => {
            let scrollDistance = window.scrollY;
            //console.log(scrollDistance);

            if(window.location.pathname == "/"){
                if(scrollDistance > (human.offsetHeight - 200)){
                    header.classList.add("position");
                } else{
                    header.classList.remove("position");
                }

                if(scrollDistance > (human.offsetHeight)){
                    header.classList.add("active");
                    header.classList.remove("front-page");
                } else {
                    header.classList.remove("active");
                    header.classList.add("front-page");
                }
            } else{
                if(scrollDistance > 1){
                    header.classList.add("position");
                } else{
                    header.classList.remove("position");
                }
                if(scrollDistance > 1){
                    header.classList.add("active");
                } else {
                    header.classList.remove("active");
                }
            }
        });

    }catch (e) {
        console.log(e + "навигация");
    }

    try{
        // Show search
        $('.search-btn').click(function() {
            $('.search').fadeIn();
            console.log("кликнули на поиск");
        });

        $(document).click(function(event) {
            if ($(event.target).closest('.search-btn').length || $(event.target).closest('.search').length ) return;
            $('.search').fadeOut();
            event.stopPropagation();
        });
    } catch (e) {
        console.log(e + "поиск");
    }

    try{
        let blockPlus = document.querySelector(".block__plus"),
            blockDescription = document.querySelector(".block__description");

        blockPlus.addEventListener("click", (e) => {
            e.preventDefault();
            if(blockDescription.classList.contains("show")){
                blockDescription.classList.remove("show");
                blockPlus.innerHTML = "<svg viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 6C12 2.68627 9.31372 -4.07116e-07 6 -2.62268e-07C2.68629 -1.17421e-07 -4.07116e-07 2.68627 -2.62268e-07 6C-1.17421e-07 9.31371 2.68629 12 6 12C9.31373 12 12 9.31371 12 6ZM3.75 5.25C3.33579 5.25 3 5.58577 3 6C3 6.41421 3.33579 6.75 3.75 6.75L5.25 6.75L5.25 8.25C5.25 8.66421 5.58578 9 6 9C6.41422 9 6.75 8.66421 6.75 8.25L6.75 6.75L8.25 6.75C8.66422 6.75 9 6.41421 9 6C9 5.58577 8.66422 5.25 8.25 5.25L6.75 5.25L6.75 3.75C6.75 3.33578 6.41422 3 6 3C5.58579 3 5.25 3.33578 5.25 3.75L5.25 5.25L3.75 5.25Z\"/></svg>";
            } else {
                blockDescription.classList.add("show");
                blockPlus.innerHTML = "<svg viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 12C9.31373 12 12 9.31373 12 6C12 2.68629 9.31373 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31373 2.68629 12 6 12ZM3.75 5.25C3.33579 5.25 3 5.58579 3 6C3 6.41423 3.33579 6.75 3.75 6.75H8.25C8.66422 6.75 9 6.41423 9 6C9 5.58579 8.66422 5.25 8.25 5.25H3.75Z\" /></svg>";
            }

        });
    } catch (e) {
        console.log(e + "плюс");
    }

    try{
        let burgerMenu = document.querySelector(".burger-menu"),
            headerNavs = document.querySelector(".header__navs");

        burgerMenu.addEventListener("click", (e) => {
            e.preventDefault();
            burgerMenu.classList.toggle("show");
            headerNavs.classList.toggle("show");

        })

    } catch (e) {
    console.log(e + "бургер меню");
}

});
